import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    christianHerter: Core.ImageAttributes;
    michaelMandelbaum: Core.ImageAttributes;
    frankGavin: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const { christianHerter, michaelMandelbaum, frankGavin } = props.data;

  return (
    <Layout title="On the Shoulders of Giants">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection
          marginalia={<Core.LeadIn>by Michael Anft</Core.LeadIn>}
        >
          <Core.LargeText fontStyle="sans" color="beige">
            During this 75th anniversary year, we celebrate the seminal
            contributions of early SAIS greats, whose intellectual DNA continues
            to run deep in their contemporary counterparts. Our second
            installment in a yearlong series.
          </Core.LargeText>
        </Core.ContentSection>
        <Core.HorizontalRule />
        <Core.ContentSection
          marginaliaStackedBottom
          marginalia={
            <>
              <Core.FullWidthImage
                image={christianHerter}
                alt="Christian Herter"
                contentSectionPlacement="marginalia"
              />
              <Core.Caption>
                <Core.LeadIn color="beige">Christian Herter</Core.LeadIn>
                <br />
                Born in Paris, France, and educated at Harvard, Herter was part
                of the U.S. delegation to the 1919 Paris Peace Conference, where
                he helped draft the Covenant of the League of Nations. Later, he
                served as assistant to Herbert Hoover during the period that
                Hoover was instrumental in providing starvation relief to
                postwar Europe. After co- founding SAIS with Paul Nitze in 1943,
                Herter served as governor of Massachusetts from 1953 to 1957 and
                U.S. Secretary of State from 1959 to 1961. The Herter name has
                had an enduring effect at SAIS. Christian Herter’s son,
                Christian Herter Jr., also a former congressman, taught
                international environmental law at the school from 1982 to 2002.
              </Core.Caption>
            </>
          }
        >
          <Core.Flag>Foreign Policy</Core.Flag>
          <Core.PrimaryHeading>Engaging with the World</Core.PrimaryHeading>
          <p>
            <Core.LeadIn color="beige">As the legend goes</Core.LeadIn>, two
            cousins by marriage picked up a Washington newspaper one day in
            1943, noted that the Russians had beaten the Nazis in the Battle of
            Stalingrad, and forecast that the United States would emerge as one
            of the winners of World War II.
          </p>
          <p>
            While this was reason enough to exhale, or perhaps anticipate a
            celebratory end to the bloodiest war in human history, the men were
            pensive. If there was to be a lasting peace after the war,
            thoughtful people would have to figure out a way to “win” that
            peace, they knew. The failure to guarantee security following World
            War I—a failure that aided the rise of fascism and Adolf Hitler—had
            plunged the world into conflict yet again. How could we avoid that
            scenario this time around, they asked each other?
          </p>
          <p>
            The cousins decided the world needed a well-educated cadre of
            academics, diplomats, and teachers to help keep it from tearing
            itself apart again. So they— Paul Nitze and Christian Herter—opened
            an independent graduate school in Washington, D.C., called the
            School of Advanced International Studies a year later. They welcomed
            23 people into its inaugural class.
          </p>
          <p>
            Nitze would emerge as the face of the new institution. Eventually,
            it would be dubbed the Paul H. Nitze School of Advanced
            International Studies.
          </p>
          <p>
            Though Herter is lesser known, he should be no less remembered, say
            many educators and leaders at SAIS. Herter is credited with setting
            the tone for SAIS’ approach to the study of foreign policy.
          </p>
          <p>
            Herter’s long association with politics—he had been a U.S.
            congressman and would go on to become governor of Massachusetts and
            the secretary of state under Dwight D. Eisenhower—had taught him the
            importance of foreign policy and its practical application:
            diplomacy. He and Nitze agreed that the world would suffer if the
            U.S. isolated itself after the war. They advocated for an
            outward-looking and open-handed diplomatic strategy that would help
            the nation project its power peacefully.
          </p>
          <p>
            “Herter was an internationalist at a time when internationalism
            wasn’t very popular, especially in the years between the world
            wars,” says Francis J. Gavin, director of the Henry A. Kissinger
            Center for Global Affairs at SAIS and Giovanni Agnelli Distinguished
            Professor. “He believed in an engaged foreign policy. He created
            SAIS in part because he wanted the United States to remain engaged
            with the world, and he wanted to prepare the next generation to be
            engaged in that way.”
          </p>
          <p>
            In the years to come, as SAIS grew and the nation entered into an
            uneasy nuclear peace with the Soviet Union, Herter’s values came to
            the fore.
          </p>
          <p>
            “SAIS was a Cold War institution,” says Michael Mandelbaum, a
            professor emeritus of American Foreign Policy; he held the Christian
            A. Herter Professorship from 1990 until his retirement in 2016.
            “That informed its ideas on diplomacy. I gave a talk about Herter’s
            legacy that explored that connection, though I never met him.
            [Herter died in 1966.] He had an effect on how we’ve gone about
            teaching foreign policy.”
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <div style={{ marginTop: '2em' }} />
              <Core.Blockquote
                author="Michael Mandelbaum"
                authorTitle=", a professor emeritus of American Foreign Policy"
                color="beige"
              >
                SAIS was a Cold War institution. That informed its ideas on
                diplomacy. I gave a talk about Herter’s legacy that explored
                that connection, though I never met him. [Herter died in 1966.]
                He had an effect on how we’ve gone about teaching foreign
                policy.
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={michaelMandelbaum}
            alt="Michael Mandelbaum"
            contentSectionPlacement="body"
            caption="Michael Mandelbaum"
          />
          <p>
            Though the two never crossed paths, Herter and Mandelbaum are linked
            by meaningful coincidences. Herter was present in 1919 at the
            inaugural meeting of the now-powerful Council on Foreign Relations,
            at which Mandelbaum worked from 1986 until his retirement, covering
            communist countries. (“I met with Gorbachev and Yeltsin during the
            fall of the Berlin Wall,” Mandelbaum says. “I had a front-row seat
            to a real rarity—a peaceful revolution.”)
          </p>
          <p>
            Herter was a giant not just at SAIS, but in the highest circles of
            government. His profile was especially heightened following world
            wars. He served as a U.S. delegate to the Paris Peace Conference in
            1919, where he took part in the drafting of the covenant of the
            League of Nations.
          </p>
          <p>
            Later, as a congressman, he was instrumental in the development and
            passage of the Marshall Plan, which funneled $100 billion (in
            today’s dollars) in U.S. aid to rebuild Europe following World War
            II.
          </p>
          <p>
            And after then-Secretary of State John Foster Dulles died in 1959,
            Eisenhower asked Herter to take the State Department helm. During
            his 18 months in office, Herter worked to involve Europe in
            multilateral nuclear talks. “At the time, there was a sense that
            Eisenhower and Dulles’ policy regarding Europe was losing steam,”
            says Gavin.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <div style={{ marginTop: '2em' }} />
              <Core.Blockquote
                author="Francis J. Gavin"
                authorTitle=", director of the Henry A. Kissinger Center for Global Affairs at SAIS and Giovanni Agnelli Distinguished Professor"
                color="beige"
              >
                Herter...created SAIS in part because he wanted the United
                States to remain engaged with the world, and he wanted to
                prepare the next generation to be engaged in that way.
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={frankGavin}
            alt="Frank Gavin"
            contentSectionPlacement="body"
            caption="Frank Gavin. Photo by Howard Korn."
          />
          <p>
            In December 1960, Herter gave a speech to NATO, one designed to
            assuage European leaders’ fears of being denied the chance to defend
            themselves. Herter hatched the idea for a multilateral force made up
            of a multinational fleet of shops outfitted with nuclear weapons.
          </p>
          <p>
            But Herter’s most lasting contribution—besides the school itself—is
            the tenor he set for the study and teaching of the way the United
            States deals with the rest of the world. Along with other giants of
            SAIS who followed him—Robert E. Osgood, Robert Tucker, and
            Mandelbaum—Herter created a program that intently focuses on
            knowledge of history, policy, and regional differences.
          </p>
          <p>
            “American foreign policy might be the department that most closely
            resembles the original idea behind SAIS,” adds Mandelbaum. “We
            educated people about it, and most of our grads went on to work for
            the government. There’s a thread in that that goes all the way back
            to the beginning, to Christian Herter.”
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    christianHerter: file(
      relativePath: { regex: $directory }
      name: { eq: "christian-herter" }
    ) {
      ...MarginaliaImage
    }
    michaelMandelbaum: file(
      relativePath: { regex: $directory }
      name: { eq: "michael-mandelbaum" }
    ) {
      ...MarginaliaImage
    }
    frankGavin: file(
      relativePath: { regex: $directory }
      name: { eq: "frank-gavin" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default FeatureContent;
