import './Heading.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

const GiantsHeading: React.FC = () => {
  const { christianHerter, michaelMandelbaumSepia } = useStaticQuery<{
    christianHerter: Core.ImageAttributes;
    michaelMandelbaumSepia: Core.ImageAttributes;
  }>(graphql`
    {
      christianHerter: file(
        relativePath: {
          regex: "/issues/fall-2019/features/on-the-shoulders-of-giants/images/"
        }
        name: { eq: "christian-herter-round" }
      ) {
        ...MarginaliaImage
      }
      michaelMandelbaumSepia: file(
        relativePath: {
          regex: "/issues/fall-2019/features/on-the-shoulders-of-giants/images/"
        }
        name: { eq: "michael-mandelbaum-round" }
      ) {
        ...MarginaliaImage
      }
    }
  `);

  return (
    <Core.Masthead color="light" className="GiantsHeading">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <div className="GiantsHeading-title-one">On the</div>
        <div className="GiantsHeading-title-two">Shoulders of Giants</div>
        <div className="GiantsHeading-portraits">
          <div className="GiantsHeading-portraits-image">
            <Core.FullWidthImage
              image={christianHerter}
              alt=""
              contentSectionPlacement="marginalia"
            />
          </div>
          <div className="GiantsHeading-portraits-image">
            <Core.FullWidthImage
              image={michaelMandelbaumSepia}
              alt=""
              contentSectionPlacement="marginalia"
            />
          </div>
        </div>
        <div className="GiantsHeading-details">
          <div className="GiantsHeading-details-author">by Michael Anft</div>
        </div>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default GiantsHeading;
